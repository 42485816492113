import type { Theme } from '@fortum/elemental-ui'
import { colors as elementalColors, generateColorLuminances } from '@fortum/elemental-ui'

export const colors = {
  ...elementalColors,

  /**
   * Ocean-Emerald 'linear-gradient(162deg, #0F7A5A 0%, #1E4F45 100%)'
   */
  oceanEmerald: `linear-gradient(162deg, ${elementalColors.oceanGreen} 0%, ${elementalColors.emeraldGreen} 100%)`,

  /**
   * Ocean-Emerald 45 deg clockwise (-45 deg)
   */
  oceanEmeraldSE45: `linear-gradient(135deg, ${elementalColors.oceanGreen} 0%, ${elementalColors.emeraldGreen} 100%)`,

  /**
   * Pale Lavender Purple '#F8E1FA'
   */
  paleLavenderPurple: '#F8E1FA',
}

/**
 * CoS semantic colors. These should be preferred to Elemental colors to support theming.
 * https://www.figma.com/file/O4zqENJ9hbrY7vDmqLBYxN/CoS-Styles?type=design&node-id=0-1&mode=dev
 */
export type CosColor = Record<
  | 'brandPrimary'
  | 'brandSecondary'
  | 'brandInfo'
  | 'brandAlert'
  | 'brandWarning'
  | 'textPrimary'
  | 'textSecondary'
  | 'textLarge'
  | 'textInverted'
  | 'textLightOnDark'
  | 'textLightOnDarkAccent'
  | 'textLightOnDarkSubtle'
  | 'textLink'
  | 'textSuccess'
  | 'textInfo'
  | 'textAlert'
  | 'textWarning'
  | 'textPositive'
  | 'textDisabled'
  | 'backgroundPrimary'
  | 'backgroundSecondary'
  | 'backgroundTertiary'
  | 'backgroundLightOnDark'
  | 'backgroundLightOnDarkGradient'
  | 'backgroundSuccess'
  | 'backgroundInfo'
  | 'backgroundAlert'
  | 'backgroundWarning'
  | 'backgroundPositive'
  | 'borderPrimary'
  | 'borderSuccess'
  | 'borderInfo'
  | 'borderAlert'
  | 'borderWarning'
  | 'borderPositive'
  | 'borderHighLight'
  | 'chart1'
  | 'chart2'
  | 'chart3'
  | 'chart4'
  | 'chart5'
  | 'chart6'
  | 'chart7'
  | 'chart8'
  | 'chart9'
  | 'chart10',
  string
>

export const lightColors: CosColor = {
  /**
   * Brand colors
   */
  brandPrimary: colors.oceanGreen,
  brandSecondary: colors.poweringGreen,
  brandInfo: colors.cushyBlue,
  brandAlert: colors.punchRed,
  brandWarning: colors.aureolinYellow,

  /**
   * Text colors
   */
  textPrimary: colors.inkGrey,
  textSecondary: colors.sonicGrey,
  textLarge: colors.emeraldGreen,
  textInverted: colors.snowWhite, // Use on backgroundTertiary
  textLightOnDark: colors.snowWhite, // Use on backgroundLightOnDark
  textLightOnDarkAccent: colors.turquoiseGreen,
  textLightOnDarkSubtle: colors.vibrantGreen,
  textLink: colors.oceanGreen,
  textSuccess: colors.emeraldGreen,
  textInfo: colors.broadBlue,
  textAlert: colors.rubyRed,
  textWarning: colors.inkGrey,
  textPositive: colors.plumPurple,
  textDisabled: colors.fogGrey,

  /**
   * Background colors
   */
  backgroundPrimary: colors.snowWhite,
  backgroundSecondary: colors.lightGrey,
  backgroundTertiary: colors.oceanGreen,
  backgroundLightOnDark: colors.emeraldGreen,
  backgroundLightOnDarkGradient: colors.oceanEmerald,
  backgroundSuccess: colors.hyperGreen,
  backgroundInfo: colors.babyBlue,
  backgroundAlert: colors.blushRed,
  backgroundWarning: colors.miniYellow,
  backgroundPositive: colors.paleLavenderPurple,

  /**
   * Border colors
   */
  borderPrimary: colors.fogGrey,
  borderSuccess: colors.emeraldGreen,
  borderInfo: colors.broadBlue,
  borderAlert: colors.rubyRed,
  borderWarning: colors.inkGrey,
  borderPositive: colors.plumPurple,
  borderHighLight: colors.turquoiseGreen,

  /**
   * Chart colors
   */
  chart1: colors.oceanGreen,
  chart2: colors.plumPurple,
  chart3: colors.cushyBlue,
  chart4: colors.goldenOrange,
  chart5: colors.punchRed,
  chart6: colors.energeticRedOrange,
  chart7: colors.forestGreen,
  chart8: colors.brownBeige,
  chart9: colors.emperorGrey,
  chart10: colors.raisinGrey,
}

export const darkColors: CosColor = {
  /**
   * Brand colors
   */
  brandPrimary: colors.poweringGreen,
  brandSecondary: colors.waterBlue,
  brandInfo: colors.waterBlue,
  brandAlert: colors.softRed,
  brandWarning: colors.aureolinYellow,

  /**
   * Text colors
   */
  textPrimary: colors.cloudGrey,
  textSecondary: colors.fogGrey,
  textLarge: colors.poweringGreen,
  textInverted: colors.raisinGrey, // Use on backgroundTertiary
  textLightOnDark: colors.snowWhite, // Use on backgroundLightOnDark
  textLightOnDarkAccent: colors.turquoiseGreen,
  textLightOnDarkSubtle: colors.vibrantGreen,
  textLink: colors.poweringGreen,
  textSuccess: colors.emeraldGreen,
  textInfo: colors.broadBlue,
  textAlert: colors.rubyRed,
  textWarning: colors.inkGrey,
  textPositive: colors.paleLavenderPurple,
  textDisabled: colors.emperorGrey,

  /**
   * Background colors
   */
  backgroundPrimary: colors.professionalGrey,
  backgroundSecondary: colors.mineShaftGrey,
  backgroundTertiary: colors.poweringGreen,
  backgroundLightOnDark: colors.emeraldGreen,
  backgroundLightOnDarkGradient: colors.oceanEmerald,
  backgroundSuccess: colors.hyperGreen,
  backgroundInfo: colors.babyBlue,
  backgroundAlert: colors.blushRed,
  backgroundWarning: colors.miniYellow,
  backgroundPositive: colors.plumPurple,

  /**
   * Border colors
   */
  borderPrimary: colors.sonicGrey,
  borderSuccess: colors.emeraldGreen,
  borderInfo: colors.broadBlue,
  borderAlert: colors.rubyRed,
  borderWarning: colors.inkGrey,
  borderPositive: colors.paleLavenderPurple,
  borderHighLight: colors.turquoiseGreen,

  /**
   * Chart colors
   */
  chart1: colors.poweringGreen,
  chart2: colors.lavenderPurple,
  chart3: colors.waterBlue,
  chart4: colors.energeticOrange,
  chart5: colors.softRed,
  chart6: colors.fallRedOrange,
  chart7: colors.electricGreen,
  chart8: colors.sunsetBeige,
  chart9: colors.fogGrey,
  chart10: colors.lightGrey,
}

/**
 * Modifies the color for the hover state.
 */
export const getHoverColor = (theme: Theme, color: string): string =>
  generateColorLuminances('hover', theme, { color }).bg

/**
 * Modifies the color for the click (active) state.
 */
export const getClickColor = (theme: Theme, color: string): string =>
  generateColorLuminances('click', theme, { color }).bg

/**
 * Modifies the color for the focus state.
 */
export const getFocusColor = (theme: Theme, color: string): string =>
  generateColorLuminances('focus', theme, { color }).bg
